/* You can add global styles to this file, and also import other style files */
// @import "./../node_modules/materialize-css/dist/css/materialize.min.css";
@import '~animate.css/animate.min';


html, body { 
    height: 100%; 
    margin: 0; 
    box-sizing: border-box;    
}
body { 
    margin: 0; 
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}

@font-face {
    font-family: 'Bauhaus';
    src: url('./../src/assets/fonts/Bauhaus93Regular.ttf')  format('truetype');
    font-weight: bold;
    font-display: auto;
}

@font-face {
    font-family: 'Gothic';
    src: url('./../src/assets/fonts/gothic.ttf')  format('truetype');
    font-weight: bold;
    font-display: auto;
}